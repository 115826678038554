import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import ScrollSection from '@components/ScrollSection'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import Container from '@components/Container'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateImage from '@components/animation/AnimateImage'
import { animation } from '@styles/vars/animation.style'
import Spacer from '@components/Spacer'
import {
  HeroMain,
  HeroText,
  HeroImage,
  HeroImageInner,
  HeroPinned,
  HeroImages,
  HeroModel,
  HeroButton,
  HeroModelInner,
} from './index.style'
import { Heading1, TextBody, TextBodySmall } from '@components/TextStyles'
import Button from '@components/Button'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import Model from './model'
import Model from './model-spline'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'

gsap.registerPlugin(ScrollTrigger)

const Hero = ({ text, images, label, price, salePrice, openOrder }) => {
  const wrapRef = useRef(null)
  const pinnedBackRef = useRef(null)
  const pinnedFrontRef = useRef(null)
  const textRef = useRef(null)

  useEffect(() => {
    let mm = gsap.matchMedia()

    mm.add('(min-width: 1024px)', () => {
      ScrollTrigger.create({
        trigger: wrapRef.current,
        start: 'top top',
        end: 'bottom top',
        pin: pinnedBackRef.current,
        onUpdate: self => {
          gsap.set(pinnedBackRef.current, {
            opacity: 1 - (self.progress - 0.3) * 3,
          })
        },
      })
    })

    mm.add('(min-width: 1024px)', () => {
      ScrollTrigger.create({
        trigger: wrapRef.current,
        start: 'top top',
        end: 'bottom bottom',
        pin: pinnedFrontRef.current,
      })
    })

    mm.add('(max-width: 1023px)', () => {
      ScrollTrigger.create({
        trigger: wrapRef.current,
        start: 'top top',
        end: 'bottom bottom',
        onLeave: () => {
          gsap.set(textRef.current, { position: 'absolute' })
        },
        onEnterBack: () => {
          gsap.set(textRef.current, { position: 'fixed' })
        },
      })
    })
  }, [])

  return (
    <ScrollSection>
      <HeroPinned ref={pinnedBackRef}>
        <Heading1>
          Meet SPARQ
          <br />
          Diagnostics
        </Heading1>
      </HeroPinned>
      <HeroPinned ref={pinnedFrontRef}>
        <Container>
          <Grid>
            <GridItem tabletP={8} tabletPStart={3} tabletL={4} tabletLStart={5}>
              <HeroModel>
                <HeroModelInner>
                  <Model />
                </HeroModelInner>
              </HeroModel>
              <HeroText ref={textRef}>
                <TextBody>
                  <AnimateSplitText delay={1 + animation.textDelay}>
                    {text}
                  </AnimateSplitText>
                </TextBody>
                <Spacer size={[16, 24]} />
                <AnimateSlideIn delay={1 + animation.textDelay * 1.5}>
                  <HeroButton>
                    <TextBodySmall>
                      {label}{' '}
                      {salePrice ? (
                        <>
                          <s>{price}</s> {salePrice}
                        </>
                      ) : (
                        price
                      )}
                    </TextBodySmall>
                    <Button size="small" onClick={openOrder}>
                      Order Now
                    </Button>
                  </HeroButton>
                </AnimateSlideIn>
              </HeroText>
            </GridItem>
          </Grid>
        </Container>
      </HeroPinned>
      <HeroMain ref={wrapRef}>
        <HeroImages>
          <Container>
            <Grid>
              <GridItem tiny={6}>
                <HeroImage>
                  <AnimateImage>
                    <HeroImageInner>
                      <GatsbyImage
                        image={images[2].gatsbyImageData}
                        alt={images[2].description}
                      />
                    </HeroImageInner>
                  </AnimateImage>
                </HeroImage>
                <Spacer size={16} />
                <HeroImage>
                  <AnimateImage>
                    <HeroImageInner>
                      <GatsbyImage
                        image={images[4].gatsbyImageData}
                        alt={images[4].description}
                      />
                    </HeroImageInner>
                  </AnimateImage>
                </HeroImage>
                <Spacer size={16} />
                <HeroImage>
                  <AnimateImage>
                    <HeroImageInner>
                      <GatsbyImage
                        image={images[6].gatsbyImageData}
                        alt={images[6].description}
                      />
                    </HeroImageInner>
                  </AnimateImage>
                </HeroImage>
              </GridItem>
              <GridItem tiny={6}>
                <Spacer size={102} />
                <HeroImage>
                  <AnimateImage>
                    <HeroImageInner>
                      <GatsbyImage
                        image={images[3].gatsbyImageData}
                        alt={images[3].description}
                      />
                    </HeroImageInner>
                  </AnimateImage>
                </HeroImage>
                <Spacer size={16} />
                <HeroImage>
                  <AnimateImage>
                    <HeroImageInner>
                      <GatsbyImage
                        image={images[5].gatsbyImageData}
                        alt={images[5].description}
                      />
                    </HeroImageInner>
                  </AnimateImage>
                </HeroImage>
                <Spacer size={16} />
                <HeroImage>
                  <AnimateImage>
                    <HeroImageInner>
                      <GatsbyImage
                        image={images[1].gatsbyImageData}
                        alt={images[1].description}
                      />
                    </HeroImageInner>
                  </AnimateImage>
                </HeroImage>
              </GridItem>
            </Grid>
          </Container>
        </HeroImages>
        <HeroImages desktop>
          <Grid>
            <GridItem
              mobile={6}
              tabletP={4}
              tabletPStart={3}
              tabletL={2}
              tabletLAlign={`center`}
            >
              <HeroImage data-scroll data-scroll-speed="4">
                <AnimateImage delay={1.3}>
                  <HeroImageInner>
                    <GatsbyImage
                      image={images[0].gatsbyImageData}
                      alt={images[0].description}
                    />
                  </HeroImageInner>
                </AnimateImage>
                <Spacer size={[53, 262]} />
              </HeroImage>
            </GridItem>
            <GridItem
              mobile={6}
              tabletP={4}
              tabletL={2}
              tabletLAlign={`center`}
            >
              <HeroImage data-scroll data-scroll-speed="1.2">
                <Spacer size={[118, 370]} />
                <AnimateImage delay={1.9}>
                  <HeroImageInner>
                    <GatsbyImage
                      image={images[1].gatsbyImageData}
                      alt={images[1].description}
                    />
                  </HeroImageInner>
                </AnimateImage>
              </HeroImage>
            </GridItem>
            <GridItem
              mobile={6}
              tabletP={4}
              tabletPStart={3}
              tabletL={2}
              tabletLAlign={`center`}
              tabletLStart={9}
            >
              <HeroImage data-scroll data-scroll-speed="1.2">
                <AnimateImage delay={1.5}>
                  <HeroImageInner>
                    <GatsbyImage
                      image={images[2].gatsbyImageData}
                      alt={images[2].description}
                    />
                  </HeroImageInner>
                </AnimateImage>
              </HeroImage>
              <Spacer size={[88, 165]} />
            </GridItem>
            <GridItem
              mobile={6}
              tabletP={4}
              tabletL={2}
              tabletLAlign={`center`}
            >
              <Spacer size={[78, 164]} />
              <HeroImage data-scroll data-scroll-speed="-1.2">
                <AnimateImage delay={1.7}>
                  <HeroImageInner>
                    <GatsbyImage
                      image={images[3].gatsbyImageData}
                      alt={images[3].description}
                    />
                  </HeroImageInner>
                </AnimateImage>
              </HeroImage>
            </GridItem>
          </Grid>
        </HeroImages>
        <HeroImages desktop>
          <Grid>
            <GridItem
              mobile={6}
              tabletP={4}
              tabletPStart={3}
              tabletL={2}
              tabletLAlign={`center`}
            >
              <HeroImage data-scroll data-scroll-speed="4">
                <AnimateImage offset={20}>
                  <HeroImageInner>
                    <GatsbyImage
                      image={images[4].gatsbyImageData}
                      alt={images[4].description}
                    />
                  </HeroImageInner>
                </AnimateImage>
                <Spacer size={[53, 262]} />
              </HeroImage>
            </GridItem>
            <GridItem
              mobile={6}
              tabletP={4}
              tabletL={2}
              tabletLAlign={`center`}
            >
              <HeroImage data-scroll data-scroll-speed="1.2">
                <Spacer size={[118, 370]} />
                <AnimateImage offset={10}>
                  <HeroImageInner>
                    <GatsbyImage
                      image={images[5].gatsbyImageData}
                      alt={images[5].description}
                    />
                  </HeroImageInner>
                </AnimateImage>
              </HeroImage>
            </GridItem>
            <GridItem
              mobile={6}
              tabletP={4}
              tabletPStart={3}
              tabletL={2}
              tabletLAlign={`center`}
              tabletLStart={9}
            >
              <HeroImage data-scroll data-scroll-speed="1.2">
                <AnimateImage offset={20}>
                  <HeroImageInner>
                    <GatsbyImage
                      image={images[6].gatsbyImageData}
                      alt={images[6].description}
                    />
                  </HeroImageInner>
                </AnimateImage>
              </HeroImage>
              <Spacer size={[88, 165]} />
            </GridItem>
            <GridItem
              mobile={6}
              tabletP={4}
              tabletL={2}
              tabletLAlign={`center`}
            >
              <Spacer size={[78, 164]} />
              <HeroImage data-scroll data-scroll-speed="-1.2">
                <AnimateImage offset={20}>
                  <HeroImageInner>
                    <GatsbyImage
                      image={images[7].gatsbyImageData}
                      alt={images[7].description}
                    />
                  </HeroImageInner>
                </AnimateImage>
              </HeroImage>
            </GridItem>
          </Grid>
        </HeroImages>
      </HeroMain>
    </ScrollSection>
  )
}

Hero.propTypes = {
  text: PropTypes.string,
  images: PropTypes.array,
}

export default Hero
